








































































































































































import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import { AddItemsModal } from "@/v2/module/pat/table-payment/add-items-modal";
import {
  defineComponent,
  PropType,
  ref,
  computed,
  getCurrentInstance,
} from "@vue/composition-api";
import {
  AtomCard,
  AtomButton,
  AtomText,
  AtomDate,
  AtomButtonSizeEnum,
  AtomIcon,
  AtomMoney,
  AtomTextColorEnum,
  AtomButtonTypeEnum,
  AtomTextTypeEnum,
  MolModalConfirm,
  MolCollapseIndicator,
} from "@/v2/new-design-system";
import { Toast } from "@/design-system";
import { IFetchTablePaymentsResponse } from "@/v2/repo/table-payment/fetch-table-payments";
import { EventEnum } from "@/v2/enum";
import { payAtTableTrack } from "../track";

const css = bemBuilder("table-payment-table-line");

type IBill = IFetchTablePaymentsResponse[number]["bill"];

export default defineComponent({
  name: "TablePaymentTableLine",
  components: {
    AtomCard,
    AtomText,
    AtomButton,
    AddItemsModal,
    AtomDate,
    AtomIcon,
    AtomMoney,
    MolModalConfirm,
    MolCollapseIndicator,
  },
  props: {
    tableName: {
      type: String,
      required: true,
    },
    tableId: {
      type: String,
      required: true,
    },
    bill: {
      type: Object as PropType<IBill>,
      default: null,
    },
    onCloseBill: {
      type: Function as PropType<(value: string, billId: string) => void>,
      required: true,
    },
    isClosingBill: {
      type: Boolean,
      required: true,
    },
    isPosIntegrated: {
      type: Boolean,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    isCloseBillDisabled: {
      type: Boolean,
      required: true,
    },
    onRefreshTable: {
      type: Function as PropType<(tableId: string) => void>,
      required: true,
    },
    isRefreshingTable: {
      type: Boolean,
      required: true,
    },
    isRefreshTableDisabled: {
      type: Boolean,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
    outletName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isAddItemsVisible = ref(false);

    const isCollapsed = ref(true);
    const showDeleteModal = ref(false);

    const currentInstance = getCurrentInstance();
    // @ts-ignore
    const analytics = currentInstance?.proxy.$analytics;
    function eventTrack(event: EventEnum, label: String) {
      payAtTableTrack(analytics, event, props.businessId, {
        outlet_id: props.outletId,
        outlet_name: props.outletName,
        table_id: props.tableId,
        label,
      });
    }

    const openModalItems = () => {
      isAddItemsVisible.value = true;
    };

    const onItemsAdded = () => {
      isAddItemsVisible.value = false;
      props.onRefreshTable(props.tableId);
    };

    const disabledClose = computed(() => {
      return Boolean(props.isCloseBillDisabled || props.isRefreshingTable);
    });

    const disabledRefresh = computed(() => {
      return Boolean(props.isRefreshTableDisabled || props.isClosingBill);
    });

    async function closeBill() {
      if (!props.bill?.id) return;

      await props.onCloseBill(props.tableId, props.bill.id);

      showDeleteModal.value = false;

      new Toast().create({
        type: "success",
        title: t("module.pat.table_payment.table_line.close_bill_toast_title"),
        text: t("module.pat.table_payment.table_line.close_bill_toast_desc"),
      });
    }

    function refreshTable() {
      if (!disabledRefresh.value) {
        props.onRefreshTable(props.tableId);
        eventTrack(EventEnum.PAY_AT_TABLE_TABLE_ROW, "Refresh");
      }
    }

    function toggleCollapse() {
      if (props.bill) {
        isCollapsed.value = !isCollapsed.value;
        eventTrack(EventEnum.PAY_AT_TABLE_TABLE_ROW, "Expand table");
      }
    }

    return {
      t,
      css,
      closeBill,
      isCollapsed,
      showDeleteModal,
      onItemsAdded,
      disabledClose,
      openModalItems,
      disabledRefresh,
      isAddItemsVisible,
      refreshTable,
      toggleCollapse,
      AtomButtonSizeEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonTypeEnum,
    };
  },
});
