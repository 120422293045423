








































































































import { bemBuilder } from "@/v2/util/bem-builder";
import { TablePaymentAddItemForm } from "@/v2/module/pat/table-payment/add-item-form";
import { updateItemsOnBill } from "@/v2/repo/update-items-on-bill";
import { createBillForTable } from "@/v2/repo/create-bill-for-table";
import {
  defineComponent,
  PropType,
  computed,
  ref,
  onMounted,
  getCurrentInstance,
} from "@vue/composition-api";
import {
  AtomButton,
  AtomModal,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomButtonTypeEnum,
  AtomMoney,
  AtomButtonSizeEnum,
} from "@/v2/new-design-system";
import { Toast } from "@/design-system";
import { CurrencyCodeEnum } from "@/v2/enum";
import { IFetchTablePaymentsBill } from "@/v2/repo/table-payment/fetch-table-payments";
import { t, tc } from "@/i18n";
import { EventEnum } from "@/v2/enum";
import { payAtTableTrack } from "../track";

type IBillItems = IFetchTablePaymentsBill["items"];

const css = bemBuilder("add-items-modal-add-items-modal");

interface IItem {
  id: string;
  name: string;
  quantity: number;
  unitPrice: number;
}

export default defineComponent({
  name: "AddItemsModal",
  components: {
    AtomModal,
    AtomButton,
    AtomMoney,
    AtomText,
    TablePaymentAddItemForm,
  },
  props: {
    outletId: {
      type: String,
      required: true,
    },
    tableId: {
      type: String,
      required: true,
    },
    billId: {
      type: String,
      default: "",
    },
    billItems: {
      type: Array as PropType<IBillItems>,
      default: () => [],
    },
    onAdded: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onCancel: {
      type: Function as PropType<() => void>,
      required: true,
    },
    onClose: {
      type: Function as PropType<() => void>,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
    outletName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const items = ref<IItem[]>([generateEmptyLine()]);
    const currencyCode = ref<CurrencyCodeEnum>(CurrencyCodeEnum.AED);
    const shouldValidName = ref(false);

    const currentInstance = getCurrentInstance();
    // @ts-ignore
    const analytics = currentInstance?.proxy.$analytics;
    function eventTrack(event: EventEnum, label: String) {
      payAtTableTrack(analytics, event, props.businessId, {
        outlet_id: props.outletId,
        outlet_name: props.outletName,
        table_id: props.tableId,
        label,
      });
    }

    function generateEmptyLine() {
      return {
        unitPrice: 0,
        quantity: 1,
        name: "",
        id: new Date().getTime().toString(),
      };
    }

    onMounted(() => {
      if (props.billItems && props.billItems.length) {
        currencyCode.value = props.billItems[0].unitPrice.currencyCode;
        items.value = props.billItems.map((item) => ({
          ...item,
          unitPrice: item.unitPrice.currencyValue,
        }));
      }
    });

    const addLine = () => {
      items.value = [...items.value, generateEmptyLine()];
      eventTrack(EventEnum.PAY_AT_TABLE_ORDER_ITEMS, "Add item");
    };

    const totalPrice = computed(() => {
      return Number(
        items.value.reduce((acc, currentItem) => {
          return acc + currentItem.unitPrice * currentItem.quantity;
        }, 0)
      );
    });

    const onItemChanged = (index: number, item: IItem) => {
      const currentItems = [...items.value];
      currentItems[index] = {
        ...item,
        id: currentItems[index].id,
      };
      items.value = currentItems;
    };

    const onItemRemoved = (index: number) => {
      const currentItems = [...items.value];
      currentItems.splice(index, 1);
      items.value = currentItems;
      eventTrack(EventEnum.PAY_AT_TABLE_ORDER_ITEMS, "Delete item");
    };

    const isLoading = ref(false);

    function addItems() {
      shouldValidName.value = true;

      if (items.value.find((obj) => !obj.name)) return;

      props.billId ? updateBillItems() : createBillWithItems();

      shouldValidName.value = false;
    }

    function showErrorToast() {
      new Toast().create({
        type: "error",
        title: t(
          "module.pat.table_payment.add_items_modal.toast_content.failure.title"
        ),
        text: t(
          "module.pat.table_payment.add_items_modal.toast_content.failure.subtitle"
        ),
      });
    }

    function showSuccessToast() {
      new Toast().create({
        type: "success",
        title: t(
          "module.pat.table_payment.add_items_modal.toast_content.success.title"
        ),
        text: t(
          "module.pat.table_payment.add_items_modal.toast_content.success.subtitle"
        ),
      });
    }

    async function createBillWithItems() {
      isLoading.value = true;

      try {
        await createBillForTable(props.outletId, props.tableId, items.value);
        props.onClose();
        props.onAdded();
        showSuccessToast();
      } catch (e) {
        // TODO: Sentry?
        showErrorToast();
      } finally {
        isLoading.value = false;
      }
    }

    async function updateBillItems() {
      isLoading.value = true;
      try {
        await updateItemsOnBill(props.outletId, props.billId, items.value);
        props.onClose();
        props.onAdded();
        showSuccessToast();
      } catch (e) {
        // TODO: Sentry?
        showErrorToast();
      } finally {
        isLoading.value = false;
      }
    }

    function validName(name: string) {
      if (shouldValidName.value && !name) return true;

      return false;
    }

    return {
      t,
      tc,
      css,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      validName,
      currencyCode,
      items,
      onItemChanged,
      addLine,
      addItems,
      AtomButtonTypeEnum,
      AtomButtonSizeEnum,
      onItemRemoved,
      createBillWithItems,
      updateItemsOnBill,
      isLoading,
      totalPrice,
    };
  },
});
