









import { t } from "@/i18n";
import { defineComponent } from "@vue/composition-api";
import { bemBuilder } from "@/v2/util/bem-builder";
import { OrgHeaderInfo } from "@/v2/new-design-system";

const css = bemBuilder("pat-history");

export default defineComponent({
  name: "PATHistory",
  components: {
    OrgHeaderInfo,
  },
  setup() {
    return { t, css };
  },
});
