import { render, staticRenderFns } from "./AddItemForm.vue?vue&type=template&id=17962296&scoped=true&"
import script from "./AddItemForm.vue?vue&type=script&lang=ts&"
export * from "./AddItemForm.vue?vue&type=script&lang=ts&"
import style0 from "./AddItemForm.vue?vue&type=style&index=0&id=17962296&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17962296",
  null
  
)

export default component.exports