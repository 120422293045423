import { http } from "../http";
import { IRepoErrors } from "../errors";

export class UpdateItemsOnError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: UpdateItemsOn");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function updateItemsOnBill(
  outletId: string,
  billId: string,
  items: Array<{ name: string; quantity: number; unitPrice: number }>
): Promise<boolean> {
  try {
    await http.put(`/pat/locations/${outletId}/bills/${billId}/items`, {
      items: items.map((item) => ({
        name: item.name,
        quantity: item.quantity,
        unit_price: item.unitPrice,
      })),
    });
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new UpdateItemsOnError(error);
  }

  return true;
}
