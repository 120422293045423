import { IFetchTablePaymentsResponse } from "@/v2/repo/table-payment/fetch-table-payments";

export function tablePaymentListSort(
  tablePaymentList: IFetchTablePaymentsResponse
): IFetchTablePaymentsResponse {
  const isPaidTables = tablePaymentList.filter(
    (table) => table.bill?.isOpen && table.bill?.isPaid
  );
  const isPartialPaidTables = tablePaymentList.filter(
    (table) => table.bill?.isOpen && !table.bill?.isPaid
  );
  const isUnopenedTables = tablePaymentList.filter(
    (table) => !table.bill?.isOpen
  );

  return [...isPaidTables, ...isPartialPaidTables, ...isUnopenedTables];
}
