






































































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  computed,
  watch,
  onMounted,
  onUnmounted,
  getCurrentInstance,
} from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomMoney,
  AtomLoading,
  MolSearchBox,
  MolMultiselect,
} from "@/v2/new-design-system";
import { TablePaymentTableLine } from "../table-line";
import { CurrencyCodeEnum } from "@/v2/enum";
import {
  fetchTablePayments,
  IFetchTablePaymentsResponse,
} from "@/v2/repo/table-payment/fetch-table-payments";
import { fetchTodaysTips } from "@/v2/repo/table-payment/fetch-todays-tips";
import { fetchTotalPaid } from "@/v2/repo/table-payment/fetch-total-paid";
import { closeTablePayment } from "@/v2/repo/table-payment/close-table-payment";
import { TablePaymentAudioNotification } from "../audio-notification";
import { tablePaymentListSort } from "../methods/table-payment-list-sort";
import { useRouter } from "@/router";
import { report } from "@chatfood/bug-reporter";
import { t, tc } from "@/i18n";
import { EventEnum } from "@/v2/enum";
import { payAtTableTrack } from "../track";
import { fetchAllBusinessLocations } from "@/v2/repo/table-payment/fetch-all-business-locations";
import { fetchFoodicsBranches } from "@/v2/repo/outlet/fetch-foodics-branches";

const css = bemBuilder("table-payment-main");

interface IOutlet {
  id: string;
  name: string;
}

export default defineComponent({
  name: "TablePaymentMain",
  components: {
    AtomText,
    AtomMoney,
    AtomLoading,
    MolSearchBox,
    MolMultiselect,
    TablePaymentTableLine,
    TablePaymentAudioNotification,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const isLoading = ref(false);
    const searchQuery = ref("");

    const currentlyClosingTableId = ref("");
    const currentlyRefreshingTableId = ref("");

    const outletList = ref<Array<IOutlet>>([]);
    const outletSelected = ref<IOutlet>();

    const tablePaymentList = ref<IFetchTablePaymentsResponse>([]);
    type ITablePaymentLine = IFetchTablePaymentsResponse[number];

    const oneMinute = 60000;
    const fifteenSeconds = oneMinute / 4;
    const fiveMinutes = oneMinute * 5;

    const intervalGetTablePayments = ref();
    const intervalGetTodaysTips = ref();

    onMounted(() => {
      intervalGetTablePayments.value = setInterval(
        getTablePayments,
        fifteenSeconds
      );

      intervalGetTodaysTips.value = setInterval(getTodaysTips, fiveMinutes);
    });

    onUnmounted(() => {
      clearInterval(intervalGetTablePayments.value);
      clearInterval(intervalGetTodaysTips.value);
    });

    const tablePaymentListSorted = computed(() => {
      return tablePaymentListSort(tablePaymentList.value);
    });

    const tablePaymentListFiltered = computed(() => {
      const query = searchQuery.value.trim().toLowerCase();

      if (!query) return tablePaymentListSorted.value;

      if (query === "paid") {
        return tablePaymentListSorted.value.filter(
          (tableLine: ITablePaymentLine) => tableLine.bill?.isPaid || ""
        );
      }

      return tablePaymentListSorted.value.filter(
        (tableLine: ITablePaymentLine) =>
          tableLine.tableName.toLowerCase().includes(query) ||
          (tableLine.bill && tableLine.bill.id.toLowerCase().includes(query))
      );
    });

    const hasBillPaid = computed(() => {
      return tablePaymentListSorted.value.some(
        (tableLine: ITablePaymentLine) => tableLine.bill?.isPaid
      );
    });

    const todayTipsAmount = ref(0);
    const todayTipsCurrency = ref<CurrencyCodeEnum | null>(null);

    const todayTotalPaidAmount = ref(0);
    const todayTotalPaidCurrency = ref<CurrencyCodeEnum | null>(null);

    watch(
      () => props.businessId,
      () => getOutletList(),
      {
        immediate: true,
      }
    );

    watch(
      () => props.outletId,
      () => {
        props.outletId ? initTablePayments() : getOutletList();
      },
      {
        immediate: true,
      }
    );

    async function getOutletList() {
      try {
        const data = await fetchAllBusinessLocations(props.businessId);
        if (data.length) {
          outletList.value = data.map(({ id, name }) => ({
            id,
            name,
          }));

          if (props.outletId) {
            outletSelected.value = outletList.value.find(
              (outlet: IOutlet) => outlet.id === props.outletId
            );
            isLinkedToFoodics(props.outletId);
            return;
          }

          updateOutletRoute(
            outletSelected.value
              ? outletSelected.value.id
              : outletList.value[0].id
          );
        }
      } catch (e: any) {
        const { error = {} } = e;
        report(e, {
          context: {
            error,
          },
          tags: {
            "http.response_code": error.status,
          },
        });
      }
    }

    async function getTablePayments(tableId: string = "") {
      if (!props.outletId) return;

      if (tableId !== "") {
        currentlyRefreshingTableId.value = tableId;
      }

      try {
        tablePaymentList.value = await fetchTablePayments(props.outletId);
      } catch (e: any) {
        const { error = {} } = e;
        report(e, {
          context: {
            error,
          },
          tags: {
            "http.response_code": error.status,
          },
        });
      } finally {
        isLoading.value = false;
        currentlyRefreshingTableId.value = "";
      }
    }

    async function getTodaysTips() {
      if (!props.outletId) return;

      try {
        const { tipValue, tipCurrency } = await fetchTodaysTips(props.outletId);
        todayTipsAmount.value = tipValue;
        todayTipsCurrency.value = tipCurrency;
      } catch (e: any) {
        todayTipsAmount.value = 0;
        todayTipsCurrency.value = null;

        const { error = {} } = e;
        report(e, {
          context: {
            error,
          },
          tags: {
            "http.response_code": error.status,
          },
        });
      }
    }

    async function getTotalPaid() {
      if (!props.outletId) return;

      try {
        const { totalPaidValue, totalPaidCurrency } = await fetchTotalPaid(
          props.outletId
        );
        todayTotalPaidAmount.value = totalPaidValue;
        todayTotalPaidCurrency.value = totalPaidCurrency;
      } catch (e: any) {
        todayTotalPaidAmount.value = 0;
        todayTotalPaidCurrency.value = null;

        const { error = {} } = e;
        report(e, {
          context: {
            error,
          },
          tags: {
            "http.response_code": error.status,
          },
        });
      }
    }

    async function initTablePayments() {
      isLoading.value = true;
      await Promise.all([getTablePayments(), getTotalPaid(), getTodaysTips()]);
      isLoading.value = false;
    }

    const router = useRouter();

    function updateOutletRoute(outletId: string) {
      outletSelected.value = outletList.value.find(
        (outlet: IOutlet) => outlet.id === outletId
      );
      isLinkedToFoodics(outletId);

      router.replace({
        name: "table-payment.outlet",
        params: {
          businessId: props.businessId,
          outletId,
        },
      });
    }

    const isPosIntegrated = ref(false);
    async function isLinkedToFoodics(outletId: string) {
      const branches = await fetchFoodicsBranches({
        businessId: props.businessId,
      });

      isPosIntegrated.value = Boolean(
        branches.find((a) => a.linkedOutletId === outletId)
      );
    }

    async function setOutlet(outlet: IOutlet) {
      if (outlet.id === props.outletId) return;
      updateOutletRoute(outlet.id);
    }

    const currentInstance = getCurrentInstance();
    // @ts-ignore
    const analytics = currentInstance?.proxy.$analytics;
    function eventTrack(
      event: EventEnum,
      tableId: String,
      tableStatus: boolean
    ) {
      payAtTableTrack(analytics, event, props.businessId, {
        outlet_id: props.outletId,
        outlet_name: outletSelected.value?.name,
        table_id: tableId,
        label: "Close bill",
        table_status: tableStatus,
      });
    }

    async function closeBill(tableId: string, billNumber: string) {
      currentlyClosingTableId.value = tableId;

      try {
        await closeTablePayment({
          outletId: props.outletId,
          billNumber,
        });

        const toChange = tablePaymentList.value.find(
          (table) => table.bill?.id == billNumber
        );

        const billStatus =
          toChange && toChange.bill ? toChange.bill?.isPaid : false;
        eventTrack(EventEnum.PAY_AT_TABLE_TABLE_PAID, tableId, billStatus);

        if (toChange) {
          toChange.bill = null;
          tablePaymentList.value = [...tablePaymentList.value];
        }

        getTablePayments();

        setTimeout(() => Promise.all([getTotalPaid(), getTodaysTips()]), 2000);
      } catch (e: any) {
        const { error = {} } = e;
        report(e, {
          context: {
            error,
          },
          tags: {
            "http.response_code": error.status,
          },
        });
      } finally {
        currentlyClosingTableId.value = "";
      }
    }

    return {
      t,
      tc,
      css,
      isLoading,
      outletList,
      outletSelected,
      setOutlet,
      todayTotalPaidAmount,
      todayTotalPaidCurrency,
      todayTipsAmount,
      todayTipsCurrency,
      searchQuery,
      closeBill,
      hasBillPaid,
      getTablePayments,
      currentlyClosingTableId,
      currentlyRefreshingTableId,
      tablePaymentList,
      tablePaymentListFiltered,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      isPosIntegrated,
    };
  },
});
