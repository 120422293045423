import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";
import { http } from "../../http";
import { CurrencyCodeEnum } from "@/v2/enum";

export type IFetchTablePaymentsBill = {
  id: string;
  remainingBalance: {
    currencyValue: number;
    currencyCode: CurrencyCodeEnum;
  };
  paidAmount: {
    currencyValue: number;
    currencyCode: CurrencyCodeEnum;
  };
  items: Array<{
    unitPrice: {
      currencyValue: number;
      currencyCode: CurrencyCodeEnum;
    };
    name: string;
    quantity: number;
    id: string;
  }>;
  paidAt: Date | null;
  isPaid: boolean;
  isOpen: boolean;
};

export type IFetchTablePaymentsResponse = Array<{
  tableId: string;
  tableName: string;
  bill: IFetchTablePaymentsBill | null;
}>;

export class FetchTablePaymentsError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchTablePayments");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchTablePayments(
  outletId: string
): Promise<IFetchTablePaymentsResponse> {
  let response: AxiosResponse;

  try {
    response = await http.get(`/pat/locations/${outletId}/tables/`);
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchTablePaymentsError(error);
  }
  return response.data
    ? response.data.map((obj: any) => ({
        tableId: obj.table_id,
        tableName: obj.table_name,
        bill: obj.bill
          ? {
              id: obj.bill.id,
              remainingBalance: {
                currencyValue: Number(obj.bill.remaining_balance.value),
                currencyCode: obj.bill.remaining_balance.currency,
              },
              paidAmount: {
                currencyValue: Number(obj.bill.amount_paid.value),
                currencyCode: obj.bill.amount_paid.currency,
              },
              items: obj.bill.items.map((item: any) => {
                const result = {
                  ...item,
                  unitPrice: {
                    currencyValue: item.unit_price.value,
                    currencyCode: item.unit_price.currency,
                  },
                };

                delete result.unit_price;
                return result;
              }),
              paidAt: obj.bill.paid_at ? new Date(obj.bill.paid_at) : null,
              isPaid: obj.bill.is_paid,
              isOpen: obj.bill.is_open,
            }
          : null,
      }))
    : [];
}
