import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";
import { http } from "../../http";
import { CurrencyCodeEnum } from "@/v2/enum";

export interface IFetchTotalPaidResponse {
  totalPaidValue: number;
  totalPaidCurrency: CurrencyCodeEnum;
}

export class FetchTotalPaidError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchTotalPaid");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchTotalPaid(
  outletId: string
): Promise<IFetchTotalPaidResponse> {
  let response: AxiosResponse;

  try {
    response = await http.get(
      `/pat/locations/${outletId}/bills/total-paid-amount`
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchTotalPaidError(error);
  }

  return {
    totalPaidValue: response.data.value,
    totalPaidCurrency: response.data.currency,
  };
}
