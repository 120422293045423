import { EventCategoryEnum, EventEnum } from "@/v2/enum/events";
import { IAnalytics } from "@/plugins/analytics/types";
import {
  analyticsEventCommonData,
  trackEvent,
} from "@/v2/util/analytics/trackEvent";

export function payAtTableTrack(
  analytics: IAnalytics,
  eventName: EventEnum,
  businessId: string,
  additionalData: Record<string, any> = {}
): void {
  trackEvent({
    eventCategory: EventCategoryEnum.PAY_AT_TABLE,
    analytics,
    eventName,
    additionalData: {
      ...analyticsEventCommonData([["business", businessId]]),
      page_name: "Pay at table",
      category: "Pay at table",
      name: "Clicked on button",
      ...additionalData,
    },
  });
}
