














































import { t } from "@/i18n";
import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  PropType,
  computed,
  ref,
  watch,
} from "@vue/composition-api";
import {
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomInput,
  OrgFormMoneyInput,
  AtomIcon,
  AtomMoney,
} from "@/v2/new-design-system";
import { CurrencyCodeEnum } from "@/v2/enum";

const css = bemBuilder("table-payment-add-item-form");

interface IItem {
  name: string;
  unitPrice: number;
  quantity: number;
}

export default defineComponent({
  name: "TablePaymentAddItemForm",
  components: {
    AtomIcon,
    AtomText,
    AtomInput,
    AtomMoney,
    OrgFormMoneyInput,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    item: {
      type: Object as PropType<{
        unitPrice: number;
        name: string;
        quantity: number;
        id: number;
      }>,
      default: null,
    },
    currency: {
      type: String as PropType<CurrencyCodeEnum>,
      required: true,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    onChange: {
      type: Function as PropType<(id: number, item: IItem) => void>,
      required: true,
    },
    onRemove: {
      type: Function as PropType<(id: number) => void>,
      required: true,
    },
  },
  setup(props) {
    const price = ref(props.item?.unitPrice || 0);
    const name = ref(props.item?.name || "");
    const quantity = ref(props.item?.quantity || 0);

    const subTotal = computed(() => {
      return quantity.value * price.value;
    });

    watch([price, name, quantity], () => {
      props.onChange(props.id, {
        unitPrice: price.value,
        name: name.value,
        quantity: quantity.value,
      });
    });

    return {
      t,
      css,
      name,
      price,
      quantity,
      subTotal,
      CurrencyCodeEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
