import { IRepoErrors } from "../../errors";
import { http } from "../../http";

interface ICloseTablePaymentRequest {
  outletId: string;
  billNumber: string;
}

export class CloseTablePaymentError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: CloseTablePayment");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function closeTablePayment(
  request: ICloseTablePaymentRequest
): Promise<boolean> {
  try {
    await http.put(
      `/pat/locations/${request.outletId}/bills/${request.billNumber}/close`
    );

    return true;
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new CloseTablePaymentError(error);
  }
}
