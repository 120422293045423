import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";
import { http } from "../../http";
import { CurrencyCodeEnum } from "@/v2/enum";

export interface IFetchTodaysTipsResponse {
  tipValue: number;
  tipCurrency: CurrencyCodeEnum;
}

export class FetchTodaysTipsError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchTodaysTips");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchTodaysTips(
  outletId: string
): Promise<IFetchTodaysTipsResponse> {
  let response: AxiosResponse;

  try {
    response = await http.get(`/pat/locations/${outletId}/total-tips`);
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchTodaysTipsError(error);
  }

  return {
    tipValue: response.data.value,
    tipCurrency: response.data.currency,
  };
}
