














import { defineComponent, ref, watch } from "@vue/composition-api";

export default defineComponent({
  name: "TablePaymentAudioNotification",
  props: {
    play: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const notification = ref<HTMLAudioElement>();

    watch(
      () => props.play,
      (val: boolean) => {
        toggleSound(val);
      },
      {
        immediate: true,
      }
    );

    function toggleSound(play: boolean) {
      play ? playNotification() : pauseNotification();
    }

    async function pauseNotification() {
      try {
        await notification.value?.pause();
      } catch {
        //
      }
    }

    async function playNotification() {
      try {
        await notification.value?.play();
      } catch {
        if (props.play) {
          setTimeout(() => playNotification(), 2000);
        }
      }
    }

    return {
      notification,
    };
  },
});
