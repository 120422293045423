import { http } from "../http";
import { IRepoErrors } from "../errors";

export class CreateBillForTableError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: createBillForTable");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function createBillForTable(
  locationId: string,
  tableId: string,
  items: Array<{ name: string; quantity: number; unitPrice: number }>
): Promise<{ id: string }> {
  try {
    const response = await http.post(
      `/pat/locations/${locationId}/tables/${tableId}/create-bill`,
      {
        items: items.map((item) => ({
          name: item.name,
          quantity: item.quantity,
          unit_price: item.unitPrice,
        })),
      }
    );
    return { id: response.data.id };
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new CreateBillForTableError(error);
  }
}
